import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ThemeMui from "@digital-hig/theme-mui";

import "./App.scss";
//Import Pages
import Authentication from "./pages/authentication";
import PageError from "./pages/error";
import Loading from "./pages/load";
import Landing from "./pages/landing";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: null,
      isLightTheme: true,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    for (const [key, value] of urlParams) {
      if (key === "theme" && value === "dark") {
        this.setState({ isLightTheme: false });
        document.body.classList.add("Dark");
      }

      if (key === "context" && value != null) {
        this.setState({ context: value });
      }
    }
  }

  render() {
    return (
      <ThemeMui
        baseThemeName={this.state.isLightTheme ? "dhig2021" : "dhig2021Dark"}
      >
        <Router>
          <Switch>
            <Route path="/load">
              <Loading isLightTheme={this.state.isLightTheme} />
            </Route>
            <Route path="/landing">
              <Landing isLightTheme={this.state.isLightTheme} />
            </Route>
            <Route path="/error">
              <PageError isLightTheme={this.state.isLightTheme} />
            </Route>
            <Route exact path="/">
              <Authentication
                context={this.state.context}
                isLightTheme={this.state.isLightTheme}
              />
            </Route>
            <Redirect to="/error" />
          </Switch>
        </Router>
      </ThemeMui>
    );
  }
}
export default App;
