import React, { Component } from "react";
import Button from "@digital-hig/button";
import TextLink from "@digital-hig/text-link";
import "./signin.scss";

const context_string = "and continue with ";

class EnterEmail extends Component {
  componentDidMount() {
    setTimeout(function () {
      document.getElementById("page-1").classList.remove("fadeOutLeft");
    }, 0);
  }

  render() {
    const handleSubmit = (event) => {
      event.preventDefault();

      let emailEntry = document.getElementById("email").value;

      if ((emailEntry != null) & (emailEntry !== "")) {
        document.getElementById("page-1").classList.add("fadeOutLeft");
        setTimeout(() => {
          this.props.handleEmail(emailEntry);
        }, 300);
      }
    };

    return (
      <div id="page-1" className="App-signin fadeOutLeft">
        <h1>Sign in</h1>
        {this.props.context != null && this.props.context !== "" && (
          <h3>
            {context_string}
            {this.props.context}
          </h3>
        )}

        <form id="form-email" onSubmit={handleSubmit} className="dhig-my-6">
          <label htmlFor="email">Email</label>
          <div className="dhig-mb-7">
            <input
              type="email"
              id="email"
              name="email"
              defaultValue="john.doe@gmail.com"
            />
          </div>
          <Button
            layout="stretch"
            size="defaultSize"
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </form>
        <div className="App-second-cta">
          <span>New to Autodesk?</span>&nbsp;
          <TextLink href="/">Create account</TextLink>
        </div>
      </div>
    );
  }
}

export default EnterEmail;
