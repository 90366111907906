import React, { Component } from "react";
import TextLink from "@digital-hig/text-link";
import Backcaret from "./back-caret";

import "./forgot.scss";

class ForgotPassword extends Component {
  componentDidMount() {
    setTimeout(function () {
      document.getElementById("page-3").classList.remove("fadeOutRight");
    }, 0);
  }

  render() {
    const handleReturn = (event) => {
      event.preventDefault();

      document.getElementById("page-3").classList.add("fadeOutRight");
      setTimeout(() => {
        this.props.handleEmail(null);
        this.props.handleForgot(false);
      }, 300);
    };

    return (
      <div id="page-3" className="App-forget fadeOutRight">
        <div className="dhig-mb-1">
          <div className="headWCaret dhig-mb-3">
            <a href="/" onClick={handleReturn} className="Change-user">
              <Backcaret />
            </a>
          </div>
          <h1>Reset password</h1>
        </div>

        <p className="dhig-my-6">
          Look in your inbox for <span>{this.props.email}</span> explaining how
          to reset your password.
        </p>
        <div className="App-second-cta">
          <span>Didn't get email?</span>&nbsp;
          <TextLink href="#">Resend</TextLink>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
