import React from "react";
import "./back-caret.scss";

export default function Backcaret(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        className="caret-stoke"
        d="M17.54,5.64,14,2.1,4.1,12,14,21.9l3.54-3.54L11.17,12ZM16.12,18.36,14,20.49,5.51,12,14,3.51l2.12,2.13L9.76,12Z"
      />
    </svg>
  );
}
