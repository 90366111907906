import React, { Component } from "react";
import Divider from "@digital-hig/divider";
import EnterEmail from "../components/signin";
import EnterPassword from "../components/password";
import ForgotPassword from "../components/forgot";
import "./authentication.scss";

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: props.context,
      isLightTheme: props.isLightTheme,
      email: null,
      forgot: false,
    };
    this.handleEmail = this.handleEmail.bind(this);
    this.handleForgot = this.handleForgot.bind(this);
  }

  handleEmail(props) {
    this.setState({
      email: props,
    });

    document.cookie = props;
  }

  handleForgot(props) {
    this.setState({
      forgot: props,
    });
  }

  render() {
    return (
      <div className={`App ${this.props.isLightTheme ? "" : "Dark"}`}>
        <div className="App-header">
          <div className="App-img" />
        </div>
        <Divider weight="strong" className="App-divider" />
        <div className="App-body">
          {this.state.email != null && this.state.email !== "" ? (
            this.state.forgot === true ? (
              <ForgotPassword
                email={this.state.email}
                handleEmail={this.handleEmail}
                handleForgot={this.handleForgot}
              />
            ) : (
              <EnterPassword
                email={this.state.email}
                handleEmail={this.handleEmail}
                handleForgot={this.handleForgot}
              />
            )
          ) : (
            <EnterEmail
              context={this.props.context}
              handleEmail={this.handleEmail}
            />
          )}
        </div>
      </div>
    );
  }
}
export default Authentication;
