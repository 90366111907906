import React from "react";
import Divider from "@digital-hig/divider";
// import Light_Loading from "../img/ring-indeterminate-xl-light-gray-100@2x.gif";
// import Dark_Loading from "../img/ring-indeterminate-xl-dark-gray-300@2x.gif";
import img_Progress_Dark from "../img/progress-dark.svg";
import img_Progress_Light from "../img/progress-light.svg";

import "./load.scss";

const Loading = (props) => {
  setTimeout(() => {
    document.getElementById("page-3").classList.remove("offsetOpacity");
  }, 300);

  setTimeout(() => {
    window.location.replace("/landing");
  }, 3000);

  return (
    <div className={`App ${props.isLightTheme ? "" : "Dark"}`}>
      <div className="App-header">
        <div className="App-img" />
      </div>
      <Divider weight="strong" className="App-divider" />
      <div className="App-body">
        <div id="page-3" className="App-loading offsetOpacity">
          {/* <img
            src={props.isLightTheme ? Light_Loading : Dark_Loading}
            alt={"Loading"}
            className="dhig-my-8"
          /> */}

          <img
            className="rotating"
            src={props.isLightTheme ? img_Progress_Light : img_Progress_Dark}
            alt={"Progress"}
          />

          <div className="dhig-my-auto dhig-ml-4">
            <h1>Signing in</h1>
            <h3>Please wait...</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
