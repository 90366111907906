import React, { Component } from "react";
import Divider from "@digital-hig/divider";
import TextLink from "@digital-hig/text-link";
import Button from "@digital-hig/button";
import Profile from "../img/profile.svg";
import img_Progress_Dark from "../img/progress-dark.svg";
import img_Progress_Light from "../img/progress-light.svg";

import "./landing.scss";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      count: 1,
    };
  }

  componentDidMount() {
    if (document.cookie != null) {
      this.setState({ email: document.cookie });
    }

    setTimeout(() => {
      this.setState({ count: 3 });
    }, 1 * 60 * 1000);
  }

  render() {
    const HandleClick = (event) => {
      event.preventDefault();
      this.setState({ count: this.state.count + 1 });

      window.location = "slack://";
    };
    return (
      <div className={`App ${this.props.isLightTheme ? "" : "Dark"}`}>
        <div className="App-header">
          <div className="App-img" />
        </div>
        <Divider weight="strong" className="App-divider"/>
        <div className="App-body App-landing">
          {/* Screen 1 */}
          {this.state.count === 1 && (
            <div className="state-1">
              <form>
                <div>
                  <img src={Profile} alt={"Profile"} />
                  <p className="email dhig-my-0">
                    {this.state.email != null
                      ? this.state.email
                      : this.props.email}
                  </p>
                  <h3 className="dhig-mt-6">
                    You are signed in as <span>{this.props.username}</span>
                  </h3>
                </div>
                <div className="dhig-mt-7 dhig-mb-6">
                  <Button
                    layout="stretch"
                    size="defaultSize"
                    variant="contained"
                    type="submit"
                    onClick={HandleClick}
                  >
                    Go to product
                  </Button>
                </div>
              </form>
              <div className="App-second-cta">
                <TextLink href="/">Switch user</TextLink>
              </div>
            </div>
          )}

          {/* Screen 2 */}
          {this.state.count === 2 && (
            <div className="state-2">
              <div>
                <h3>Opening product</h3>
              </div>
              <div className="dhig-my-6 progress">
                <img
                  className="rotating"
                  src={
                    this.props.isLightTheme
                      ? img_Progress_Light
                      : img_Progress_Dark
                  }
                  alt={"Progress"}
                />
                <p className="progress dhig-ml-2">Redirecting</p>
              </div>
              <div className="App-second-cta">
                <span>We’ve redirected you to your desktop product.</span>
                <br />
                <span>Product didn't open?</span>
                &nbsp;
                <TextLink href="slack://">Retry</TextLink>
              </div>
            </div>
          )}

          {/* Screen 3 */}
          {this.state.count === 3 && (
            <div className="state-3">
              <div>
                <img src={Profile} alt={"Profile"} />
                <p className="email dhig-my-0">
                  {this.state.email != null
                    ? this.state.email
                    : this.props.email}
                </p>
                <h3 className="dhig-mt-6 dhig-mb-7">Session expired</h3>
              </div>
              <div className="App-second-cta">
                <span>Please sign in again.</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Landing.defaultProps = {
  email: "john.doe@gmail.com",
  username: "John Doe",
};

export default Landing;
