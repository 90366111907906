import React, { Component } from "react";
import img_Error from "../img/error.svg";
import Button from "@digital-hig/button";
import "./error.scss";

class PageError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      header: null,
      body: null,
    };
  }

  componentDidMount() {
    document.body.style.backgroundImage = "unset";
    document.body.style.backgroundColor = "#fff";
    const urlParams = new URLSearchParams(window.location.search);

    for (const [key, value] of urlParams) {
      if (key === "error_code" && value != null) {
        switch (parseInt(value)) {
          case 500:
            this.setState({
              code: value,
              header: "Server error",
              body: "We are unable to complete the requested action. Please try again.",
            });
            break;
          case 404:
            this.setState({
              code: value,
              header: "Page not found",
              body: "The page you are looking for doesn't exist.",
            });
            break;
          case 403:
            this.setState({
              code: value,
              header: "Permission required",
              body: "You do not have permission to view this page.",
            });
            break;
          case 401:
            this.setState({
              code: value,
              header: "Authorization required",
              body: "You do not have permission to view this page using the credentials entered.",
            });
            break;
          default:
            this.setState({
              code: 400,
              header: "Request error",
              body: "There was a problem opening this page.",
            });
        }
      }
    }
  }

  render() {
    return (
      <div className={`App App-error ${this.props.isLightTheme ? "" : "Dark"}`}>
        <div className="App-header">
          <div className="App-img" />
        </div>
        <div className="App-body">
          <div id="page-1" className="App-signin">
            <h1>
              {this.state.code ? this.state.header : this.props.header}
            </h1>
            <div>
              <img src={img_Error} alt={"Error"} className="dhig-my-7" />
            </div>
            <div>
              <p> {this.state.code ? this.state.body : this.props.body}</p>
            </div>
            {this.state.code === "500" && (
              <div className="dhig-mt-8">
                <Button layout="stretch" size="defaultSize" variant="contained">
                  Retry
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PageError.defaultProps = {
  code: 400,
  header: "Request error",
  body: "There was a problem opening this page.",
};

export default PageError;
