import React, { Component } from "react";
import Button from "@digital-hig/button";
import TextLink from "@digital-hig/text-link";
import Checkbox from "@hig/checkbox";
import Backcaret from "./back-caret";
import "./password.scss";

class EnterPassword extends Component {
  componentDidMount() {
    setTimeout(function () {
      document.getElementById("page-2").classList.remove("fadeOutRight");
    }, 0);
  }

  render() {
    const handleForgot = (event) => {
      event.preventDefault();

      document.getElementById("page-2").classList.add("fadeOutLeft");
      setTimeout(() => {
        this.props.handleForgot(true);
      }, 300);
    };

    const handleSubmit = (event) => {
      event.preventDefault();

      document.getElementById("page-2").classList.add("fadeOutLeft");
      setTimeout(() => {
        window.location.replace("/load");
      }, 300);
    };

    const handleReturn = (event) => {
      event.preventDefault();

      document.getElementById("page-2").classList.add("fadeOutRight");
      setTimeout(() => {
        this.props.handleEmail(null);
      }, 300);
    };
    return (
      <div id="page-2" className="App-password fadeOutRight">
        <div className="dhig-mb-1">
          <div className="headWCaret dhig-mb-3">
            <a href="/" onClick={handleReturn} className="Change-user">
              <Backcaret />
            </a>
          </div>
          <h1>Welcome</h1>
        </div>
        <h3 className="dhig-mr-2">{this.props.email}</h3>

        <form id="form-password" onSubmit={handleSubmit} className="dhig-my-6">
          <label htmlFor="pwd">Password</label>

          <div className="dhig-mb-7 App-password-input">
            <TextLink
              href="/forgot"
              className="forgot dhig-px-2"
              onClick={handleForgot}
            >
              Forgot?
            </TextLink>

            <input
              type="password"
              id="pwd"
              name="password"
              defaultValue="1234678"
            />
          </div>
          <Button
            layout="stretch"
            size="defaultSize"
            variant="contained"
            type="submit"
          >
            Sign in
          </Button>
        </form>
        <div className="App-second-cta">
          <Checkbox
            id="stayed"
            label="Stay signed in"
            name="StaySignedIn"
            defaultChecked
          />
          <label htmlFor="stayed">Stay signed in</label>
        </div>
      </div>
    );
  }
}

export default EnterPassword;
